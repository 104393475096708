import axios from 'axios'
import { clone } from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { apiUrl } from './shared'
import ArticleList from './ArticleList.js'
import FeedViewerHeader from './FeedViewerHeader.js'

export default function FeedViewer(props) {
  const [articles, setArticles] = useState([])
  const [userArticles, setUserArticles] = useState([])

  let { feed_id } = useParams();

  const refreshArticles = id => {
    axios.get(`${apiUrl}/feed/${id}/articles`)
      .then(response => {
        setArticles(response.data.articles || [])
        setUserArticles(response.data.UserArticles || {})
      })
  }

  useEffect(() => {
    // load articles when a feed is selected
    if (props.feed.feed_id) {
      refreshArticles(props.feed.feed_id)
    }
  }, [props.feed])


  const findMenuFeed = id => {
    // this "loads" the feed from the feeds list menu, not another external call
    props.feeds.forEach(feed => {
      if (feed.feed_id === id) {
        props.setFeed(clone(feed))
      }
    })
  }

  useEffect(() => {
    // watch for change of feed_id (used on individual feed view)
    if (!props.feeds.length)
      return
    findMenuFeed(parseInt(feed_id))
  }, [feed_id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // watch for props.feeds (used on full page refresh)
    findMenuFeed(parseInt(feed_id))
  }, [props.feeds]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* <Link to="/">close</Link> */}
      {/* <h1 className="uk-heading-divider feed-title">{feed.name}</h1> */}
      <FeedViewerHeader
        feed={props.feed}
        articles={articles}
        refreshArticles={refreshArticles} />

      <ArticleList
        apiUrl={apiUrl}
        feed={props.feed}
        articles={articles}
        setArticles={setArticles}
        userArticles={userArticles}
        setUserArticles={setUserArticles}
        setArticle={props.setArticle}
        />

      {/* <Article
        apiUrl={apiUrl}
        article={props.article} /> */}
      {/* <Articles apiUrl={apiUrl} feed={feed} setArticle={props.setArticle} /> */}
    </div>
  )
}
