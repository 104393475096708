import axios from 'axios'
import UIkit from 'uikit'
import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import ArticleModal from './ArticleModal.js'
import {formatDateTime} from './shared'

export default function ArticleList(props) {
  const [allowMore, setAllowMore] = useState(true)
  const [articleId, setArticleId] = useState(0)

  const formatArticleAdded = date_added => {
    if (date_added) {
      const now = new Date()
      const added = new Date(date_added)
      const diff = now - added
      const minutes = Math.floor(diff / 1000 / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)
      if (days > 0) {
        return `${days}d`
      }
      if (hours > 0) {
        return `${hours}h`
      }
      if (minutes > 0) {
        return `${minutes}m`
      }
    }
    return ''
  }

  const loadMore = _ => {
    if (!props.articles.length) {
      setAllowMore(false)
      return
    }

    const article_id = props.articles[props.articles.length - 1].id
    const url = `${props.apiUrl}/feed/${props.feed.feed_id}/articles?offset=${article_id}`
    axios.get(url)
      .then(response => {
        if (response.status === 200 && response.data && response.data.articles) {
          props.setArticles(props.articles.concat(response.data.articles))
          props.setUserArticles(response.data.UserArticles)
        } else {
          setAllowMore(false)
        }
      })
      .catch(_ => {
        UIkit.notification("Error loading more articles")
      })
  }

  const tooltip = article => {
    return `Added ${formatDateTime(article.date_added)}<br />Published ${formatDateTime(article.date_published)}`
  }

  const listClass = (feed, article) => {
    if (article.date_viewed) {
      return 'uk-text-muted'
    }

    try {
      let article_date_added = new Date(article.date_added)

      // highlight "unviewed" articles
      let feed_date_viewed = new Date(feed.date_viewed)
      if (article_date_added > feed_date_viewed) {
        return 'uk-text-success'
      }

      // article older than feed's mark read date
      let unread_date = new Date(feed.date_unread_start)
      if (unread_date && unread_date > article_date_added) {
        return 'uk-text-muted'
      }
    } catch (e) {}

    return ''
  }

  // TODO: decrement unread count on the UI only
  // const decrementUnread = (feed, article) => {
  //   if (!article.is_viewed) { // (article.date_viewed) {
  //     feed.unread_articles = feed.unread_articles - 1
  //     // set feeds for propagation
  //   }
  // }

  const handleOpenArticle = (e, article) => {
    e.preventDefault()
    setArticleId(article.id)
    UIkit.modal(document.getElementById("modal-full")).show();
  }

  useEffect(() => {
    setAllowMore(true)
  }, [props.feed])

  return (
    <div>
      { !props.articles.length && <div>No articles found</div> }

      <ArticleModal
        apiUrl={props.apiUrl}
        articleId={articleId}
        setArticleId={setArticleId}
        />

      { props.articles.length > 0 &&
        <ul className="uk-list uk-list-divider">
          {props.articles.map(article => (
            <li key={article.id}>
              <span uk-tooltip={"title: " + tooltip(article)}
                className="feed-date-added uk-text-meta">
                {formatArticleAdded(article.date_added)}
              </span>
              <Link
                to={`/article/${article.id}`}
                // onClick={_ => { decrementUnread(props.feed, article) }}
                onClick={ e => { handleOpenArticle(e, article) }}
                className={listClass(props.feed, article)}
                >
                {article.title}
              </Link>
            </li>
          ))}

          { allowMore && <li>
            <button className="uk-button uk-button-small" onClick={loadMore}>more</button>
          </li> }
        </ul>
      }
    </div>
  )
}
