import axios from 'axios'
import { Routes, Route } from "react-router-dom";
import { useEffect, useState, useCallback } from 'react'
import { debounce } from "lodash";

import Layout from './Layout.js'
import FeedViewer from './FeedViewer.js'
import FeedAdd from './FeedAdd.js'
import ArticleViewer from './ArticleViewer'
import Overview from './Overview'
import Settings from './Settings.js'

export default function AppRoutes(props) {
  // TODO there has to be a better way than this to manage state
  const [feeds, setFeeds] = useState([])
  const [feed, setFeed] = useState({})
  const [feedsLoading, setFeedsLoading] = useState(false)
  const [article, setArticle] = useState({})

  // TODO when feed clicked, set spinner for feeds loading
  // when article viewed, reload feed counts
  // use Promise.all to resolve spinners https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all

  const _fetchFeeds = _ => {
    setFeedsLoading(true)
    axios.get(`${props.apiUrl}/feeds`)
      .then(response => {
        setFeeds(response.data.feeds || [])
        setFeedsLoading(false)
      })
      .then(_ => {
        setFeedsLoading(false)
      })
  }

  // create a debounce as fetchFeeds will be called quite frequently (to update counts, etc)
  const fetchFeeds = useCallback(debounce(_fetchFeeds, 250), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    if (!feeds.length) {
      fetchFeeds()
    }
  }, [feeds]) // eslint-disable-line react-hooks/exhaustive-deps

  // was used to refresh the feed list when an article was viewed (update counts); caused excessive reloading
  // useEffect(() => {
  //   fetchFeeds() // TODO reload feed.feed_id
  // }, [article])

  const layout = <Layout
    fetchFeeds={fetchFeeds}
    feedsLoading={feedsLoading}
    setFeedsLoading={setFeedsLoading}
    feeds={feeds}
    setFeeds={setFeeds}
    profile={props.profile}
    setProfile={props.setProfile}
    profileLoading={props.profileLoading}
    apiUrl={props.apiUrl}
    />

  return (
    <Routes>
      <Route path="/" element={layout}>
        <Route path="/overview" element={<Overview/>} />
        <Route path="/feed/add" element={<FeedAdd apiUrl={props.apiUrl} />} />
        <Route path="/feed/:feed_id" element={<FeedViewer apiUrl={props.apiUrl} feeds={feeds} feed={feed} setFeed={setFeed} />} />
        <Route path="/article/:article_id" element={<ArticleViewer apiUrl={props.apiUrl} article={article} setArticle={setArticle} />} />
        <Route path="/settings" element={<Settings apiUrl={props.apiUrl} profile={props.profile} setAuth={props.setAuth} setProfile={props.setProfile} />} />
      </Route>
    </Routes>
  )
}