import axios from 'axios'
import { useEffect, useState } from 'react'
import { apiUrl, formatDateTime } from './shared'

// TODO: not sure how to get around this
// if i dont set the article id out of the component, it will load the thing 3 times
let currentArticleId = 0

export default function ArticleContent(props) {
  const [article, setArticle] = useState({})
  const [loading, setLoading] = useState(true)

  const fetchArticle = id => {
    if (!id)
      return

    setLoading(true)
    setArticle({})
    return axios.get(`${apiUrl}/article/${id}`)
      .then(response => {
        let article = response.data.article
        setArticle(article)
      })
      .finally(_ => {
        setLoading(false)
      })
  }

  const createMarkup = articleHtml => {
    // TODO https://www.npmjs.com/package/dompurify
    // const clean = purify.sanitize('<b>hello there</b>');
    return {
      __html: articleHtml
    }
  }

  const handleHtmlClick = (e) => {
    if (e.target.tagName === 'A') {
      e.preventDefault()
      window.open(e.target.href, '_blank')
    }
  }

  useEffect(() => {
    if (props.articleId !== currentArticleId) {
      currentArticleId = props.articleId
      fetchArticle(currentArticleId)
    }
  }, [props.articleId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <article className="uk-article">
      <button className="uk-modal-close-default" type="button" uk-close=""></button>

      { loading && <div>Loading&hellip;</div> }

      <h1 className="uk-article-title">
        {article.title}
      </h1>
      <div className="uk-article-meta">
        <div className="uk-grid-small uk-text-middle" data-uk-grid="">
          <div className="uk-first-column">
            <button
              onClick={_ => { window.open(article.url, "_blank") } }
              className="uk-icon-button uk-icon"
              data-uk-icon="icon: link"
              data-uk-tooltip="View original"></button>
          </div>
          <div>
              Added {formatDateTime(article.date_added)}
          </div>
          {article.author && <div>Author {article.author}</div> }
        </div>
      </div>

      <div onClick={handleHtmlClick} dangerouslySetInnerHTML={createMarkup(article.preview)}></div>
      <div onClick={handleHtmlClick} dangerouslySetInnerHTML={createMarkup(article.content)}></div>
    </article>
  )
}