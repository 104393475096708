import { useEffect } from 'react';
import ArticleContent from './ArticleContent'
import UIkit from 'uikit'

const modalId = 'modal-full'

export default function ArticleModal(props) {
  const openModal = _ => {
    UIkit.modal(document.getElementById(modalId)).show();
  }

  UIkit.util.on(document, 'hidden', `#${modalId}`, _ => {
    props.setArticleId(undefined)
  })

  useEffect(_ => {
    if (props.articleId) {
      openModal()
    }
  }, [props.articleId])

  return (
    <div id="modal-full" className="uk-flex-top uk-modal-container" uk-modal="" >
      <div className="uk-modal-dialog uk-margin-auto-vertical" >
        <div className="uk-padding-large">
          <button className="uk-modal-close-default" type="button" uk-close=""></button>
          <ArticleContent
            articleId={props.articleId}
            setArticleId={props.setArticleId}
            />
        </div>
      </div>
    </div>
  )
}
