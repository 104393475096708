import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ArticleModal from './ArticleModal.js'

// TODO: not sure how to get around this
// if i dont set the article id out of the component, it will load the thing 3 times
let currentArticleId = 0

export default function ArticleViewer(props) {
  let { article_id } = useParams();
  const [articleId, setArticleId] = useState(0)

  useEffect(() => {
    if (article_id !== currentArticleId) {
      currentArticleId = article_id
      setArticleId(article_id)
    }
  }, [props.articleId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ArticleModal
      articleId={articleId}
      setArticleId={setArticleId}
     />
  )
}
