import axios from 'axios'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export default function FeedAdd(props) {
  const nameRef = useRef()
  const urlRef = useRef()

  const save = _ => {
    if (urlRef.current.value) {
      const data = {
        feed: {
          name: nameRef.current.value,
          url: urlRef.current.value,
        }
      }
      axios.post(`${props.apiUrl}/feed`, data)
      .then(response => {
        navigate(`/feed/${response.data.feed.feed_id}`)
      })
    }
    // form validation .uk-form-danger
  }

  const navigate = useNavigate()
  const cancel = e => {
    navigate('/')
  }

  return (
    <form className="uk-form-stacked feed-form">
      <fieldset className="uk-fieldset">
        <legend className="uk-legend">
          Feed
        </legend>

        <div className="uk-margin">
          <div>
            <label className="uk-form-label">Name</label>
            <input type="text" id="feed-name" ref={nameRef} className="uk-input uk-form-width-large" placeholder="Name" />
          </div>
        </div>

        <div className="uk-margin">
          <div>
            <label className="uk-form-label">Link</label>
            <span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: link"></span>
            <input type="url" id="feed-name" ref={urlRef} className="uk-input uk-form-width-large" placeholder="Url" />
          </div>
        </div>

        <div>
          <div className=" uk-text-right">
            <button type="button" className="uk-button uk-text-right" onClick={cancel} aria-label="Close" uk-close="" stlye={{"float":"right"}}></button>
            <button type="button" className="uk-button uk-button-primary" onClick={save}>Save</button>
          </div>
        </div>
      </fieldset>
    </form>
  )
}