import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { authUrl } from './shared'

export default function Settings (props) {
  const navigate = useNavigate()

  const logout = _ => {
    axios.post(`${authUrl}/logout`)
      .then(response => {
        if (response.status === 200) {
          props.setAuth(undefined)
          props.setProfile(undefined)
          navigate('/')
        }
      })
  }

  return (
    <div>
      <h1 className="uk-article-title">{props.profile.name}</h1>

      <div className="uk-margin">
          <img src={props.profile.photo_url} alt="You" />
      </div>

      <div className="uk-margin">
        <button className="uk-button uk-button-default" onClick={logout}>Logout</button>
      </div>

    </div>
  )
}
