import axios from 'axios'

const apiUrl = process.env.REACT_APP_FEED_API_URL
const authUrl = process.env.REACT_APP_FEED_AUTH_URL
const googleRedirectUri = process.env.REACT_APP_FEED_GOOGLE_REDIRECT_URI


/**
 * Holds the authorization token from auth server.
 */
const authToken = {
  refresh: undefined,
  token: undefined
}

const configureAxios = _ => {
  axios.defaults.withCredentials = true
  axios.interceptors.request.use(function (config) {
      config.withCredentials = true

      if (authToken.token)
        config.headers.Authorization = `Bearer ${authToken.token}`;

      return config;
  });
}

const getAuth = async _ => {
  if (!authToken.token)
    authToken.token = await fetchToken()

  return authToken.token
}

const setAuth = code => {
  authToken.token = code
}

/**
 * Loads an authorization token from the server (if it exists)
 * @returns <token | undefined>
 */
const fetchToken = async _ => {
  const response = await axios.get(`${authUrl}/token`)
  return (response.status === 200 && response.data && response.data.token)
    ? response.data.token
    : undefined
}

/**
 * Format a mysql datetime string into a user friendly string.
 * @param {string} date
 * @returns string
 */
const formatDateTime = date => {
  try {
    return new Date(date).toLocaleString()
  } catch {}
  return ''
}

export {
  apiUrl,
  authUrl,
  getAuth,
  setAuth,
  formatDateTime,
  configureAxios,
  googleRedirectUri
}
