import FeedList from './FeedList.js'
import { Outlet } from "react-router-dom";

export default function Layout(props) {
  return (
    <div className="uk-container">
      <div className="uk-grid" uk-grid="true">
        {/* left */}
        <div className="uk-width-1-3@m first-column">
          <div className="uk-card uk-card-default uk-card-body">
            <FeedList
              apiUrl={props.apiUrl}
              fetchFeeds={props.fetchFeeds}
              feedsLoading={props.feedsLoading}
              setFeed={props.setFeed}
              profile={props.profile}
              profileLoading={props.profileLoading}
              feeds={props.feeds}
              setFeeds={props.setFeeds}
              />
          </div>
        </div>
        {/* /left */}
        {/* right */}
        <div className="uk-width-expand@m">
          <div className="uk-container">
            <Outlet />
          </div>
        </div>
        {/* /right */}
      </div>
    </div>
  )
}