import React from 'react'
import { Link } from "react-router-dom";

const FeedImage = props => {
  // TODO: no way to know if this image exists here
  return (
    <img src={`https://bucket.nibelheim.dev/favicons/${props.feed.feed_id}.png` } alt={props.feed.name} />
  )
}

const FeedMenuList = props => {
  return (
    props.feeds
    ? props.feeds.map(feed => (
      <li key={feed.feed_id}>
        <span className="uk-text-muted feed-unread-count">{feed.unread_articles}</span>
        <Link to={`/feed/${feed.feed_id}`}>
          <FeedImage feed={feed} />
          {feed.name}
        </Link>
      </li>
    ))
    : <li>no feeds found</li>
  )
}

export default function FeedList(props) {

  // const loadFeeds = _ => {
  //   if (!props.feedsLoading) {
  //     props.fetchFeeds()
  //   }
  // }
  // useEffect(() => {
  //   if (!props.feedsLoading && !props.feeds.length) {
  //     loadFeeds()
  //   }
  // })

  return (
    <ul className="uk-nav uk-nav-default">
      <li className="uk-nav-header refresh-feeds">
        <Link to="/overview">feed</Link>
      </li>

      <li className="uk-nav-divider"></li>

      <li>
        <Link to={`/feed/add`}>
          <span className="uk-margin-right" data-uk-icon="icon: plus-circle"></span>
          Add Feed
        </Link>
      </li>

      <li className="uk-nav-divider"></li>

      { props.feedsLoading
        ? <div>Loading feeds&hellip;</div>
        : <FeedMenuList feeds={props.feeds} /> }

      <li className="uk-nav-divider"></li>

      <li>
          { props.profileLoading
          ? <div>Loading profile&hellip;</div>
          : <Link to={`/settings`}>
            <img
              src={props.profile.photo_url}
              alt={props.profile.name}
              className="uk-coment-avatar feed-avatar" />
            <div>{props.profile.name}</div>
          </Link> }
      </li>

    </ul>
  )
}
