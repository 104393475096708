import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

import axios from 'axios';
import { useState, useEffect } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'

import Login from './Login.js'
import AppRoutes from './AppRoutes.js'

// TODO shared context
import { apiUrl, authUrl, getAuth, setAuth, configureAxios } from './shared'

function App() {
  const [profile, setProfile] = useState()
  const [profileLoading, setProfileLoading] = useState(false)

  configureAxios()

  const loadProfile = _ => {
    setProfileLoading(true)

    axios.get(`${authUrl}/profile`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.profile) {
          setProfile(response.data.profile)
        }
    })
    .finally(_ => {
      setProfileLoading(false)
    })
  }

  // https://reactjs.org/docs/hooks-reference.html#usereducer
  // const initProfileValue = _ => {
  //   return {id:undefined}
  // }
  // const initProfile = _ => {
  // }
  // const profileReducer = (state, action) => {
  //   switch (action.type) {
  //     case 'init':
  //       return initProfileValue()
  //     case 'load':
  //       return loadProfile()
  //     default:
  //       throw new Error()
  //   }
  // }
  // useReducer(profileReducer, initProfile, initProfileValue)

  useEffect(() => {
    if (!profile) {
      getAuth()
      loadProfile()
    }
  }, [profile])

  useEffect(() => {
    UIkit.use(Icons)
  })

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>

      { !profile &&
      <Login
        apiUrl={apiUrl}
        authUrl={authUrl}
        setAuth={setAuth}
        profile={profile}
        setProfile={setProfile}
        />
      }

      { profile &&
      <AppRoutes
        apiUrl={apiUrl}
        profile={profile}
        setAuth={setAuth}
        setProfile={setProfile}
        profileLoading={profileLoading}
        />
      }
    </GoogleOAuthProvider>
  );
}

export default App;
