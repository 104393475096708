import axios from 'axios'
import { useGoogleLogin } from '@react-oauth/google'
import { useNavigate } from 'react-router-dom'

import { authUrl, googleRedirectUri } from './shared'

function Login(props) {
  const callbackUrl = `${props.authUrl}/auth`
  const navigate = useNavigate()

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      axios.post(callbackUrl, {  // backend that will exchange the `code`
        code,
      })
      .then(response => {
        if (response.status === 200 && response.data && response.data.profile) {
          props.setAuth(code)
          props.setProfile(response.data.profile)
          navigate('/overview')
        }
      })
    },
    redirect_uri: googleRedirectUri,
    flow: 'auth-code',
  });

  return (
    <div className="uk-section">
      <div className="uk-container uk-container-small">

        <div className="uk-card uk-card-default">
          <div className="uk-card-header">
            <h3 className="uk-card-title">Login</h3>
          </div>

          <div className="uk-card-body">
            {/* <GoogleLogin flow='auth-code' onSuccess={loginSuccess} onError={() => { console.error('Login Failed'); }} /> */}
            { !props.profile && <button className="uk-button uk-button-primary" onClick={() => googleLogin()}>Sign in with Google</button> }
          </div>

          <div className="uk-card-body">
            <a href={`${authUrl}/oauth/login`} className="uk-button uk-button-primary">OAuth2 Redirect Login</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
