import axios from 'axios'
import { Link } from 'react-router-dom'
import UIKit from 'uikit'

import { apiUrl } from './shared'

export default function FeedViewerHeader(props) {
  const markRead = feed_id => {
    return axios.patch(`${apiUrl}/feed/${feed_id}/read`)
    .then(response => {
      if (response.data.result) {
        UIKit.notification("Feed marked as read")
      }
    })
  }

  const tooltip = feed => {
    return `Unread: ${feed.unread_articles}<br />Total: ${feed.article_count}`
  }

  return (
    <nav className="uk-navbar-container uk-navbar uk-margin" uk-navbar="">
      <div className="nav-feed-name">
        <Link to={`/feed/${props.feed.feed_id}`} className="uk-navbar-item uk-logo">
          {props.feed.name}
        </Link>
      </div>
      <div className="uk-navbar-right">
        <ul className="uk-iconnav">
          <li uk-tooltip={"title: " + tooltip(props.feed)}>
            {props.articles.length} articles
          </li>
          <li>
            <button
              uk-tooltip="title: Mark read"
              uk-icon="icon: check"
              onClick={ _ => markRead(props.feed.feed_id) }></button>
          </li>
        </ul>

      </div>
    </nav>
  )
}

/*
  const refreshFeed = id => {
    return axios.get(`${apiUrl}/feed/${id}/refresh`)
    .then(response => {
      if (response.data.result) {
        let result = response.data.result
        UIKit.notification(`Feed refreshed! Parsed ${result.item_count} Added ${result.saved} Errors: ${result.errors}`)
      }
    })
    .catch(_ => {
      UIKit.notification("Error refreshing feed")
    })
  }
  <li>
    <button
      uk-tooltip="title: Reload saved articles"
      uk-icon="icon: refresh"
      onClick={ _ => props.refreshArticles(props.feed.feed_id) }></button>
  </li>
  <li>
    <button
      uk-tooltip="title: Pull new articles from source"
      uk-icon="icon: cloud-download"
      onClick={ _ => refreshFeed(props.feed.feed_id) }></button>
  </li>
  */